
















































































import Multiselect from 'vue-multiselect'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import CloseCircle from '@/app/ui/assets/close_circle_alt.vue'

@Component({
  components: {
    Multiselect,
    ExpandIcon,
    CloseCircle,
  },
})
export default class DropdownSelect extends Vue {
  @Prop({ default: () => [] }) private options!: Array<Map<string, unknown>>
  @Prop({ default: false }) private isError!: boolean
  @Prop({ default: 'normal' }) private tagStyle!: 'normal' | 'blue'
  @Prop({ default: false }) private optionSingleLine!: boolean

  get tagStyleClass(): string {
    if (this.tagStyle !== 'normal') {
      return 'tag-style-' + this.tagStyle
    }

    return ''
  }
}
