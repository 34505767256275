var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Multiselect',_vm._b({class:[
    'dropdown-select',
    _vm.tagStyleClass,
    {
      error: _vm.isError,
      disabled: _vm.$attrs.disabled,
      multiple: _vm.$attrs.multiple,
      'option-single-line': _vm.optionSingleLine,
    } ],attrs:{"data-testid":"global-component-multi-select-wrapper","options":_vm.options,"track-by":"value","label":"label","showLabels":false},on:{"input":function($event){return _vm.$emit('input', $event)},"search-change":function($event){return _vm.$emit('search', $event)},"remove":function($event){return _vm.$emit('remove', $event)},"tag":function($event){return _vm.$emit('tag', $event)},"close":function($event){return _vm.$emit('close', $event)},"open":function($event){return _vm.$emit('open', _vm.$attrs)}},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [(_vm.$scopedSlots['singleLabel'])?_vm._t("singleLabel",null,{"dataTestid":"global-component-multi-select__labelName","props":props}):_c('span',{staticClass:"font-normal"},[_vm._v(_vm._s(props.option.label))])]}},{key:"tag",fn:function(ref){
  var option = ref.option;
  var remove = ref.remove;
return (_vm.tagStyle === 'blue')?[(_vm.$scopedSlots['tag'])?_vm._t("tag",null,{"option":option,"remove":remove}):_c('div',{staticClass:"multiselect__tag",attrs:{"data-testid":"global-component-multi-select__optionLabel"}},[_c('span',{staticClass:"flex items-center"},[_vm._v(" "+_vm._s(option.label)+" "),_c('span',{staticClass:"cursor-pointer",attrs:{"data-testid":"global-component-multi-select__optionLabel__event-remove"},on:{"click":function($event){return remove(option)}}},[_c('CloseCircle',{staticClass:"ml-1.5"})],1)])])]:undefined}}],null,true)},'Multiselect',_vm.$attrs,false),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})}),(!_vm.$slots['caret'])?_c('ExpandIcon',{staticClass:"expand-icon",attrs:{"slot":"caret","fill":"#A7A8AA"},slot:"caret"}):_vm._e(),(!_vm.$slots['noResult'])?_c('template',{slot:"noResult"},[_c('span',{attrs:{"data-testid":"global-component-multi-select__search-notFound"}},[_vm._v(" Search not found ")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }